<tui-combo-box
  *tuiLet="formControlValue$ | async as formControlValue"
  [tuiTextfieldPrefix]="prefix"
  [class]="componentClass"
  #comboBoxComponent
  [tuiTextfieldLabelOutside]="labelOutside"
  [tuiTextfieldSize]="size"
  [strict]="strict"
  [strictMatcher]="strictMatcher"
  [identityMatcher]="identityMatcher"
  [formControl]="formControl"
  [search]="this.searchValue$.value"
  [stringify]="stringify(itemsMap$ | async)"
  (searchChange)="onSearch($event)"
  [tuiDropdownLimitWidth]="'min'"
  (focusedChange)="onFocusChange($event)"
  (keydown.enter)="onEnterPress()"
  [(tuiDropdownOpen)]="isDropdownOpen"
  (tuiDropdownOpenChange)="onOpenChange($event)"
>
  <input tuiTextfield autocomplete="off" />
  <ng-container *tuiDataList>
    <cdk-virtual-scroll-viewport
      *tuiLet="itemsFiltered$ | async as filtered"
      (scrolledIndexChange)="onScroll($event)"
      [style.width.px]="dropdownWidth$ | async"
      [style.max-width.px]="800"
      tuiScrollable
      class="scroll"
      [itemSize]="itemSize"
      [style.height.px]="itemHeight$ | async"
    >
      <tui-data-list>
        <button
          class="whitespace-nowrap overflow-hidden"
          *cdkVirtualFor="let item of filtered"
          tuiOption
          [value]="item[itemValue]"
          [class.selected]="isSelected(item[itemValue], formControlValue)"
        >
          {{ item[itemText] }}
        </button>
      </tui-data-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  {{ label }}
</tui-combo-box>
