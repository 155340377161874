import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  DestroyRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiInputModule,
  TuiStringifyContentPipeModule,
} from '@taiga-ui/kit';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiAutoFocusModule,
  TuiFocusedModule,
  TuiFocusVisibleModule,
  TuiLetModule,
  tuiPure,
  TuiStringHandler,
  TuiValueChangesModule,
} from '@taiga-ui/cdk';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  tap,
  withLatestFrom,
  combineLatest,
  shareReplay,
  startWith,
  Observable,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IndexChangeDirective } from '../../../directives/index-change.directive';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiDataListWrapperModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    AsyncPipe,
    TuiStringifyContentPipeModule,
    TuiComboBoxModule,
    TuiDataListModule,
    TuiLetModule,
    FormsModule,
    TuiHostedDropdownModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    TuiScrollbarModule,
    IndexChangeDirective,
    NgIf,
    TuiFilterByInputPipeModule,
    CdkVirtualForOf,
    TuiValueChangesModule,
    TuiFocusedModule,
    TuiFocusVisibleModule,
    TuiPrimitiveTextfieldModule,
    TuiDropdownModule,
    TuiAutoFocusModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T>
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @ViewChild('comboBoxComponent', { read: ElementRef })
  comboBoxComponent: ElementRef;

  formControl: FormControl;

  @Input({ required: false })
  itemText: keyof T = 'text' as keyof T;

  @Input({ required: false })
  autoSelectFirst = true;

  @Input({ required: false })
  strict = true;

  @Output()
  valueChanged = new EventEmitter<Nullable<string>>();

  @Input({ required: false })
  itemSearch: keyof T = '' as keyof T;

  @Input({ required: false })
  displayValue: keyof T = '' as keyof T;

  @Input({ required: false })
  size: 's' | 'm' | 'l' = 'm';

  @Input({ required: false })
  label = '';

  @Input({ required: false })
  labelOutside = true;

  @Input({ required: false })
  prefix = '';

  @Input({ required: false })
  componentClass = '';

  searchValue$ = new BehaviorSubject<string | null>('');
  initialValue$ = new BehaviorSubject<string>('');
  isDropdownOpen = false;
  dropdownWidth$ = new BehaviorSubject<number>(100);
  items$ = new BehaviorSubject<T[]>([]);
  itemsMap$ = new BehaviorSubject<Map<string, T>>(new Map());
  itemValue$ = new BehaviorSubject<keyof T>('value' as keyof T);

  itemsFiltered$ = combineLatest([
    this.searchValue$.pipe(delay(0), distinctUntilChanged()),
    this.items$,
  ]).pipe(
    map(([value, items]) => {
      const formControlValue = this.formControl.getRawValue();
      if (formControlValue) {
        return this.getFilteredItemsByValue(formControlValue);
      }
      if (!value) {
        return items;
      }
      return this.getFilteredItemsByValue(value);
    }),
    tap(() => this.recalculateDropDownWidth$.next(0)),
  );

  itemHeight$ = this.itemsFiltered$.pipe(
    map(items => items.length),
    distinctUntilChanged(),
    map(length => this.getViewportHeight(length)),
    shareReplay(),
  );

  recalculateDropDownWidth$ = new Subject<number>();
  formControlValue$: Observable<any>;

  constructor(
    private readonly injector: Injector,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onSearch(searchValue: string | null) {
    this.searchValue$.next(searchValue);
  }

  onEnterPress() {
    if (!this.isDropdownOpen) {
      this.openDropdown();
    }
    this.completeIfPossible();
  }

  onFocusChange(focused: boolean) {
    if (!focused) {
      const formControlValue = this.formControl.getRawValue();
      this.closeDropdown();
      if (
        this.autoSelectFirst &&
        this.searchValue$.value !== this.initialValue$.value
      ) {
        this.completeIfPossible();
      }
      if (this.initialValue$.value !== formControlValue) {
        this.valueChanged.emit(formControlValue);
        this.initialValue$.next(formControlValue);
      }
    }
  }

  onOpenChange(open: boolean) {
    if (open) {
      this.recalculateDropDownWidth$.next(0);
    }
  }

  onScroll(startIndex: number) {
    this.recalculateDropDownWidth$.next(startIndex);
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  strictMatcher(t: unknown, e: unknown, stringify: (value: unknown) => string) {
    return stringify(t).toLowerCase() === e?.toString().toLowerCase();
  }

  identityMatcher(t: unknown, e: unknown) {
    return t?.toString().toLowerCase() === e?.toString().toLowerCase();
  }

  getFullItemsMap(items: any[] | null) {
    const identity: Array<[string, any]> =
      items?.map(item => {
        const id = item[this.itemValue]?.toString();
        return [id, item];
      }) ?? [];
    return new Map(identity);
  }

  getDisplayValueMap(items: any[] | null) {
    const identity: Array<[string, string]> =
      items?.map(item => {
        const id = item[this.itemValue];
        const name = item[this.displayValue];
        return [id, name];
      }) ?? [];
    return new Map(identity);
  }

  get items(): T[] {
    return this.items$.value;
  }

  @Input({
    required: true,
  })
  set items(value: T[] | null) {
    this.items$.next(value ?? []);
    this.recalculateDropDownWidth$.next(0);
  }

  @tuiPure
  get itemSize() {
    if (this.size === 'm') {
      return 40;
    }
    if (this.size === 's') {
      return 32;
    }
    return 40;
  }

  get itemValue() {
    return this.itemValue$.value;
  }

  @Input({ required: false })
  set itemValue(value: keyof T) {
    this.itemValue$.next(value);
  }

  isSelected(itemValue: keyof T, formControlValue: Nullable<string>) {
    return itemValue?.toString() === formControlValue?.toString();
  }

  stringify(itemsMap: Nullable<Map<string, T>>): TuiStringHandler<unknown> {
    return this.stringifySearchValue.bind(this, itemsMap);
  }

  @tuiPure
  stringifySearchValue(
    itemsMap: Nullable<Map<string, T>>,
    identity: unknown,
  ): string {
    const identityValue = identity?.toString() || '';
    const item = itemsMap?.get(identityValue);
    if (!item) {
      return '';
    }
    const key = this.displayValue || this.itemValue;
    return item[key]?.toString() ?? '';
  }

  openDropdown() {
    this.isDropdownOpen = true;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  getViewportHeight(length: number | undefined) {
    const calculatedHeight = (length ?? 1) * 40 + 8;
    const maxHeight = 200;
    return Math.min(maxHeight, calculatedHeight);
  }

  completeIfPossible() {
    if (this.searchValue$.value) {
      const [firstSelected] = this.getFilteredItemsByValue(
        this.searchValue$.value,
      );
      this.selectOption(firstSelected);
    }
  }

  selectOption(item: any): void {
    const value = item ? item[this.itemValue] : this.formControl.defaultValue;
    this.formControl.setValue(value);
  }

  createIdentityMap() {
    this.items$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.itemsMap$.next(this.getFullItemsMap(this.items$.value));
    });
  }

  recalculateDropdownWidth() {
    this.recalculateDropDownWidth$
      .pipe(
        filter(() => this.isDropdownOpen),
        withLatestFrom(this.itemsFiltered$),
        tap(([index, itemsFiltered]) => {
          const items = itemsFiltered.length ? itemsFiltered : this.items;
          const visible = items
            .slice(index, Math.min(index + 15, items.length))
            .map(i => i[this.itemText]?.toString().length ?? 0);
          const currentMaxWidth = Math.max(...visible) * 10 + 15;
          this.dropdownWidth$.next(
            Math.max(this.dropdownWidth$.value, currentMaxWidth),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
    this.formControl = (ngControl as FormControlDirective).form;

    this.recalculateDropdownWidth();
    this.createIdentityMap();

    this.formControlValue$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      distinctUntilChanged(),
      shareReplay(),
    );
  }

  getFilteredItemsByValue(value: string) {
    const key = this.itemSearch || this.itemValue;
    return this.items.filter(item =>
      item[key]
        ?.toString()
        .toLowerCase()
        .includes(value?.toString().toLowerCase()),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.searchValue$.next(value);
    this.initialValue$.next(value);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const minWidth =
        this.comboBoxComponent.nativeElement.getBoundingClientRect().width;
      this.dropdownWidth$.next(minWidth);
    });
  }
}
